import React, { PropsWithChildren, createContext, useState } from 'react'
import { useToggle } from 'react-use'
import { messageForError } from './ErrorFallback'
import { TTModal } from 'globalComponents/TTModal'
import { GenericErrorWithRedirect } from 'utilities/ExpectedErrors'
import { useNavigate } from 'react-router-dom'

interface ErrorModalContextProps {
  showErrorModal: (error: Error) => void
}

export const ErrorModalContext = createContext<ErrorModalContextProps | undefined>(undefined)

const ErrorModalProvider: React.FC<PropsWithChildren> = props => {
  const [isOpen, toggleModal] = useToggle(false)
  const [error, setError] = useState<Error>()
  const navigate = useNavigate()
  const redirect = error instanceof GenericErrorWithRedirect ? error.redirect : undefined

  return (
    <ErrorModalContext.Provider value={{ showErrorModal }}>
      <TTModal
        isOpen={isOpen}
        onOpenChange={hideErrorModal}
        title={error?.name || 'Error'}
        footerButton={
          redirect
            ? {
              copy: redirect.title,
              action: () => {
                navigate(redirect.path)
                hideErrorModal()
              }
            } : undefined}
        isErrorModal
      >
        { error && messageForError(error) }
      </TTModal>
      { props.children }
    </ErrorModalContext.Provider>
  )

  function showErrorModal(error: Error) {
    setError(error)
    toggleModal(true)
    console.error(error)
  }

  function hideErrorModal() {
    setError(undefined)
    toggleModal(false)
  }
}

export default ErrorModalProvider