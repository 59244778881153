import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TTLinkButton from 'globalComponents/fields/TTLinkButton'
import TTButton from 'globalComponents/fields/TTButton'
import * as api from 'utilities/api'
import destinations from 'utilities/destinations'
import _ from 'lodash'
import TTRadio from 'globalComponents/fields/TTRadio'
import { Button, Dialog, DialogTrigger, DialogTriggerProps, OverlayArrow, Popover, Radio } from 'react-aria-components'
import { ReactComponent as Alert } from 'assets/icons/alert.svg'
import { ReactComponent as Info } from 'assets/icons/info.svg'
import { ReactComponent as Motorcycle } from 'assets/icons/motorcycle.svg'
import { ReactComponent as Accessibile } from 'assets/icons/accessible.svg'
import { GenericErrorWithRedirect } from 'utilities/ExpectedErrors'
import usePreviousStep from 'utilities/hooks/usePreviousStep()'
import useErrorModal from 'utilities/hooks/useErrorModal()'
import styles from './index.module.css'
import { ActivatePayload, ForcedCheckoutReason } from 'utilities/types'
import TTLoader from 'globalComponents/TTLoader'
import * as Sentry from '@sentry/react'

interface Inputs {
  reason?: ForcedCheckoutReason
}

const ParkingActivateForce: React.FC = () => {
  const navigate = useNavigate()
  const { barcodeNum } = useParams()
  const [query] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const { handleSubmit, control } = useForm<Inputs>({ defaultValues: { reason: undefined } })
  const prev = usePreviousStep()
  const showErrorModal = useErrorModal()

  // For ada tooltip
  const [isADATooltipOpen, setADAToolTipOpen] = useState(false)
  const [hasADAToolTipBeenOpenedOnce, setADAToolTipOpenedOnce] = useState(false)

  const parkedInLotName = query.get('parkedInLotName') || undefined
  const passIsForLotName = query.get('passIsForLotName') || undefined
  const plate = query.get('plate') || undefined

  useEffect(() => {
    if (!hasADAToolTipBeenOpenedOnce && isADATooltipOpen) {
      setADAToolTipOpenedOnce(true)
    }
  }, [isADATooltipOpen])

  const onSubmit: SubmitHandler<Inputs> = async data => {
    submit(data)
  }

  return (
    <>
      { loading ? <TTLoader/> : <></> }
      <section className={styles.forceActivationSection}>
        <h1> <Alert/> Alert</h1>
        <h2>Lot Mismatch Detected</h2>
        { explainDetection() }
        <p>If you are confident that you are in the correct location for your pass, you may still proceed with the checkout process by selecting one of the options below:</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={loading}>
            <TTRadio<Inputs>
              control={control}
              name='reason'
              rules={{ required: 'Please select a reason or cancel and relocate your car.' }}
              aria-label='Select an option or cancel activation'
              onChange={handleToolTipOpen}
            >
              <Radio value={ForcedCheckoutReason.ada}>
                <Accessibile/>
                <div>I have an ADA placard</div>
                <TTTooltip reason={ForcedCheckoutReason.ada} isOpen={isADATooltipOpen} onOpenChange={setADAToolTipOpen}/>
              </Radio>
              <Radio value={ForcedCheckoutReason.parkedHere}>
                <div>
                  { passIsForLotName
                    ? <>I am parked in the <strong>{_.upperCase(passIsForLotName)}</strong></>
                    : <>I am parked in the correct parking lot.</>
                  }
                </div>
                <TTTooltip reason={ForcedCheckoutReason.parkedHere}/>
              </Radio>
              <Radio value={ForcedCheckoutReason.motorcycle}>
                <Motorcycle/>
                <div>I have a motorcycle</div>
              </Radio>
            </TTRadio>
            <div className={styles.buttonContainer}>
              <TTButton buttonType='primary' type='submit'>Activate anyways</TTButton>
              <TTLinkButton buttonType='secondary' href={destinations.parking.route()}>Cancel and relocate</TTLinkButton>
            </div>
          </fieldset>
        </form>
      </section>
    </>
  )

  function handleToolTipOpen(value: string) {
    if (value === ForcedCheckoutReason.ada && !hasADAToolTipBeenOpenedOnce) {
      setADAToolTipOpen(true)
    }
  }

  function explainDetection() {
    if (parkedInLotName && passIsForLotName) {
      return <p>It looks like you’re trying to activate a pass for the <strong>{_.upperCase(passIsForLotName)}</strong> but our systems indicate that your vehicle may be located in the <strong>{_.upperCase(parkedInLotName)}.</strong></p>
    }
    return <p>Your car appears to be in a different lot than the pass you are attempting to activate is for.</p>
  }

  async function submit(data: Inputs) {
    try {
      setLoading(true)

      if (!barcodeNum) throw new Error('Ticket information not found')
      // Shouldn't happen, but then again no errors should happen :/
      if (!plate) {
        throw new GenericErrorWithRedirect(
          'Something went wrong',
          'License plate information not found. Please go back to the previous step and try again',
          { path: prev.prevDestination || destinations.parking.route(), title: 'Start over' }
        )
      }
      if (!data.reason) throw Error('You must select a reason to continue')


      const payload: ActivatePayload = {
        plate,
        barcode: barcodeNum,
        forceCheckout: true,
        forceReason: data.reason
      }

      const response = await api.v1.activate(payload)
      if (response.success) {
        navigate(destinations.parking.activate(barcodeNum!).completed.route()) // Safe bang
      } else {
        throw new Error('Force checkout unsuccessful, please try again')
      }
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error)
        showErrorModal(error)
      } else {
        console.error(error)
      }
    } finally {
      setLoading(false)
    }
  }
}

interface Props extends Omit<DialogTriggerProps, 'children'> {
  reason: ForcedCheckoutReason.ada | ForcedCheckoutReason.parkedHere
}

const TTTooltip: React.FC<Props> = props => {
  const adaReason = <><strong>NOTICE:</strong> ensure your ADA placard is properly displayed and visible to avoid receiving a violation.</>
  const incorrectDetectionReason = <><strong>NOTICE:</strong> we are activating the parking session despite your captured location. If this option is checked, you acknowledge that you risk receiving a violation for parking in a lot not reflected on your parking pass.</>

  return (
    <DialogTrigger {...props}>
      <Button><Info className='info-icon'/></Button>
      <Popover placement='top' isOpen={props.isOpen} onOpenChange={props.onOpenChange}>
        <OverlayArrow>
          <svg width={12} height={12} viewBox='0 0 12 12'>
            <path d='M0 0 L6 6 L12 0' />
          </svg>
        </OverlayArrow>
        <Dialog>{props.reason === ForcedCheckoutReason.ada ? adaReason : incorrectDetectionReason}</Dialog>
      </Popover>
    </DialogTrigger>
  )
}

export default ParkingActivateForce