import { format } from 'date-fns'
import { flatten } from 'lodash'
import timezones from 'timezone-abbreviations'

export const classNames = (...objs: any) => flatten(objs).filter(Boolean).join(' ')

export const formatDateForTicket = (date: Date) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzAbbr = timezones.find(t => t.names?.includes(tz))?.abbr
  return `${format(date, 'ccc, LLL d • p')} ${tzAbbr || tz}`
}

export const formatDateForReceipt = (date: Date) => {
  return format(date, 'ccc, LLL d, y p')
}

export const formatTime = (date: Date) => {
  return format(date, 'p')
}

export const formatDate = (date: Date) => {
  return format(date, 'LLL d, y')
}

// Uppercase, Alphanumerics only
export const normalizeLicensePlate = (lp: string) => {
  return lp.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
}

export const normalizePhoneNumber = (value: string) => {
  if (!value) {
    return value
  }
  let numerals = value.replace(/[^\d]/g, '')

  // special cases for deletion
  if (value.length - numerals.length === 2) { numerals = numerals.slice(0, -1) }

  // decorating input in the National Format
  return numerals.length === 0 ? '' : numerals.length <= 3
    ? `(${numerals.substring(0, 3)}) `
    : numerals.length <= 6
      ? `(${numerals.substring(0, 3)}) ${numerals.substring(3, 6)}`
      : `(${numerals.substring(0, 3)}) ${numerals.substring(3, 6)}-${numerals.substring(6)}`
}