import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Input, Label, Text } from 'react-aria-components'
import TTButton from 'globalComponents/fields/TTButton'
import { normalizeLicensePlate } from 'utilities/etc'
import destinations from 'utilities/destinations'
import { ThemeContext, TicketsContext } from 'App'
import * as api from 'utilities/api'
import { ActivatePayload } from 'utilities/types'
import TTLoader from 'globalComponents/TTLoader'
import _ from 'lodash'
import OnPageError from 'globalComponents/errors/OnPageError'
import { ActivateError, ActivateFailReason } from 'utilities/ExpectedErrors'
import useErrorModal from 'utilities/hooks/useErrorModal()'
import ParkingPassCard from 'globalComponents/ParkingPassCard'
import { ReactComponent as CaretDown } from 'assets/icons/caret-down.svg'
import * as Sentry from '@sentry/react'
interface Inputs {
  plate: string
}

const ParkingActivate: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const showErrorModal = useErrorModal()

  const { barcodeNum } = useParams()
  const tickets = useContext(TicketsContext).tickets
  const ticket = tickets.find(t => t.barcode === barcodeNum)
  const welcomeMessage = useContext(ThemeContext).skin?.welcomeMessage
  const pastPlates = useContext(ThemeContext).plates
  const plates = _.sortedUniqBy([...pastPlates], plate => plate.plate)

  const { handleSubmit, control } = useForm<Inputs>({
    defaultValues: { plate: _.first(pastPlates)?.plate || '' }
  })

  const onSubmit: SubmitHandler<Inputs> = async data => {
    try {
      setLoading(true)

      if (!barcodeNum) throw new ActivateError(ActivateFailReason.invalidTicket)
      const payload: ActivatePayload = {
        ...data,
        barcode: barcodeNum,
        forceCheckout: false
      }

      const response = await api.v1.activate(payload)

      if (response.success) {
        navigate(destinations.parking.activate(barcodeNum).completed.route())
      } else if (response.failReason === ActivateFailReason.rateLotMismatch) {
        navigate(destinations.parking.activate(barcodeNum).force.route({
          ...response.upgradeInfo,
          ...data
        }))
      }
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error)
        showErrorModal(error)
      } else {
        console.error(error)
      }
    } finally {
      setLoading(false)
    }
  }

  if (!ticket) {
    return <OnPageError error={new ActivateError(ActivateFailReason.invalidTicket, barcodeNum)} backToParkingPasses/>
  }

  return (
    <>
      { loading && <TTLoader/> }
      <h1>{ welcomeMessage }</h1>
      <ParkingPassCard ticket={ticket} />
      { ticket
        && <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={loading}>
            <Controller
              name='plate'
              control={control}
              rules={{
                required: 'Please fill in your license plate number.',
                minLength: {value: 2, message: 'License plate must be at least 2 characters long.'}
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) =>
                <div style={{ width: '100%' }}>
                  <Label>License plate number</Label>
                  <div className='data-list-container'>
                    <Input
                      list='plate-list'
                      placeholder='ABC123'
                      onChange={e => {
                        e.currentTarget.value = normalizeLicensePlate(e.currentTarget.value)
                        onChange(e)
                      }}
                      value={value}
                    />
                    <div>
                      { <CaretDown style={{ opacity:
                        plates.find(p => p.plate.includes(value)) ? 1 : 0
                      }}/> }
                    </div>
                  </div>
                  { error ? <Text slot='errorMessage' className='react-aria-FieldError'>{error.message}</Text> : <></> }
                  <datalist id='plate-list'>
                    { plates.map(p => <option key={p.plate} value={p.plate}>{p.plate}</option>) }
                  </datalist>
                </div>
              }
            />
            <TTButton buttonType='primary' type='submit'>Activate</TTButton>
          </fieldset>
        </form>
      }
    </>
  )
}

export default ParkingActivate
