import * as api from 'utilities/fetch++'
import { ActivatePayload, ActivateResponse, MLBTicket, PlateObject, ReceiptPayload, ReceiptResponse, WelcomeResponse } from './types'
import { ActivateError, ActivateFailReason } from './ExpectedErrors'
import destinations from './destinations'

export const v1 = {
  welcome: async(csrfToken: string) => {
    const result = await api.post<WelcomeResponse>('/welcome', { csrfToken })
    if (!result.success) {
      throw new Error('Failed to fetch your session, please try again or contact support if the problem persists.')
    }
    return result
  },

  tickets: async() => {
    const result = await api.post<{ tickets: MLBTicket[] | undefined }>('/tickets')
    if (!result?.tickets?.length) return []
    return result.tickets
  },

  activate: async(data: ActivatePayload) => {
    const result = await api.post<ActivateResponse>('/activate', data)
    if (!result.success) {
      if (result.failReason === ActivateFailReason.rateLotMismatch) {
        return result
      } else if (result.failReason === ActivateFailReason.ticketRedeemed) {
        const redirect = {
          path: destinations.parking.activate(data.barcode).completed.route(),
          title: 'View receipt'
        }
        throw new ActivateError(result.failReason, data.barcode, redirect)
      } else if (result.failReason) {
        throw new ActivateError(result.failReason, data.barcode)
      } else {
        throw new Error('Activation failed, please try again later')
      }
    }
    return result
  },

  // Partial for convenience
  receipt: async(data: Partial<ReceiptPayload>) => {
    if (!data.barcode) throw new Error('No ticket found')
    const result = await api.post<ReceiptResponse>('/receipt', data)
    if (!result.success) throw new Error(`No receipt found for ticket: ${data.barcode}`)
    return result.receipt
  },

  convenience: async(partialPlate: string) => {
    const result = await api.post<{ plates?: PlateObject[] }>('/convenience', { partialPlate })
    if (!result.plates) return []
    return result.plates
  },

  errorLog: async(error: Error) => {
    if (window.location.hostname !== 'localhost') {
      await api.post('/errorlog', { errorMessage: error.message })
    }
  }
}