import { ActivateFailReason } from './ExpectedErrors'

declare global {
  interface Window {
    authToken?: string;
  }
}

export interface MLBTicket {
  ticketID: string
  publicDescription: string
  secondaryTitle: string
  priceScaleDescription: string
  priceScaleID: string
  tdcEventID: string
  barcode: string
  eventStartTime: Date
  eventEndTime: Date
  currentlyScanned: boolean
}

export interface MLBReceiptMini {
  transactionID: string
  receiptToken: string
  receiptType: string
  invoiceID: string
  plate: string
  state: string
  startTime: Date // Receipt start time, not ticket
  endTime?: Date // Receipt end time, not ticket
  locationName: string
  receiptID: string
  barcode: string
  amountPaid: number
  subtotal: number
  tax?: number
  convenienceFee?: number
  discount?: number
  promoCodeID?: string
  showTax: false
  showConvenience: false
  lotName?: string
}
export interface UpgradeInfo {
  parkedInLotName: string
  passIsForLotName: string
}

interface WelcomeResponseSuccess {
  success: true;
  skin: MLBSkinMini;
  plates: PlateObject[];
  mlbSessionToken: string;
}

interface WelcomeResponseFailure {
  success: false;
  skin?: never;
  plates?: never;
  mlbSessionToken?: never;
}

export type WelcomeResponse = WelcomeResponseSuccess | WelcomeResponseFailure

export interface MLBSkinMini {
  logoURL: string
  logo2URL: string
  colorPrimary: string
  colorSecondary: string
  colorNeutral: string
  colorOnNeutral: string
  welcomeMessage: string
  locationName: string
  supportPhone: string
  supportEmail: string
}

export interface PlateObject {
  plate: string
  lastUsed: Date
}
// TODO, currently we just get a string token
interface _MLBSessionToken {
  mlbSessionID: string
  oktaID: string
  enterpriseID: string
  locationID: string
}
export enum ForcedCheckoutReason {
  ada = 'ADA',
  motorcycle = 'Motorcycle',
  parkedHere = 'ParkedHere'
}

export type ActivatePayload = {
  plate: string
  barcode: string
  forceCheckout: false
  forceReason?: never
} | {
  plate: string
  barcode: string
  forceCheckout: true
  forceReason: ForcedCheckoutReason
}

export type ActivateResponse = {
  success: boolean
  validTicket: boolean
  failReason?: ActivateFailReason
  upgradeInfo?: UpgradeInfo
} | {
  success: false
  validTicket: boolean
  failReason?: ActivateFailReason.rateLotMismatch
  upgradeInfo: UpgradeInfo
}

export interface ReceiptPayload {
  barcode: string
}

interface ReceiptResponseSuccess {
  success: true
  receipt: MLBReceiptMini
}

interface ReceiptResponseFailure {
  success: false
  receipt?: never
}

export type ReceiptResponse = ReceiptResponseSuccess | ReceiptResponseFailure

export interface ConveniencePayload {
  partialPlate: string
}