import React, { PropsWithChildren, useState } from 'react'
import { Button, Dialog, Heading, Modal, ModalOverlayProps, PressEvent } from 'react-aria-components'
import TTButton from './fields/TTButton'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Alert } from 'assets/icons/alert.svg'
import { classNames } from 'utilities/etc'

interface FooterButtonProps {
  copy: string,
  action: (e: PressEvent) => void
}

type Props = ModalOverlayProps & {
  title: string
  isErrorModal?: boolean
  footerButton?: FooterButtonProps
}

export const TTModal: React.FC<PropsWithChildren<Props>> = props => {
  const showFooterButton = !!props.footerButton
  const [closePressed, setClosePressed] = useState(false)

  return <Modal {...props}>
    <Dialog>
      { ({close}) =>
        <>
          { header(close) }
          <section className='modal-body'>
            { props.children }
          </section>
          <section className='modal-footer'>
            <TTButton buttonType={showFooterButton ? 'secondary' : 'primary'} onPress={close}>Close</TTButton>
            {
              showFooterButton &&
          <TTButton buttonType='primary' onPress={props.footerButton?.action}>
            { props.footerButton?.copy }
          </TTButton>
            }
          </section>
        </>
      }
    </Dialog>
  </Modal>

  function header(onPress?: ((e: PressEvent) => void)) {
    if (!props.isErrorModal) {
      return <section className='modal-header'>
        <Heading slot='title'>{ props.title }</Heading>
        <Button
          onPress={onPress}
          className={classNames('modal-close', closePressed && 'modal-close-pressed')}
          onPressStart={() => setClosePressed(true)}
          onPressEnd={() => setClosePressed(false)}
        >
          <Close/>
        </Button>
      </section>
    }

    return <section className='modal-error-header'>
      <span className='alert-icon'><Alert/></span>
      <Heading slot='title'>{ props.title }</Heading>
      <Button
        onPress={onPress}
        className={classNames('modal-close', closePressed && 'modal-close-pressed')}
        onPressStart={() => setClosePressed(true)}
        onPressEnd={() => setClosePressed(false)}
      >
        <Close/>
      </Button>
    </section>
  }
}